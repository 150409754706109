import React, {Fragment, useCallback, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import {Container} from "components/Structure";
import {Exist} from "components";
import hamburger from "assets/img/hamburger.svg";
import hamburger_dark from "assets/img/hamburger_dark.svg";
import {useDispatch, useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";
import {
	ACCOUNT_LINK,
	FANTASY_LINK,
	getLoginLink,
	SecretGateController,
	useMediaQuery,
	usePrevious,
} from "modules/utils";
import logo_dark from "assets/img/logo_dark.svg";
import logo_light from "assets/img/logo_light.svg";
import {ReactComponent as MotoGPLogo} from "assets/img/motogp_logo.svg";
import {RouteComponentProps} from "react-router";
import {LangSelector} from "./LangSelector";
import {MobileLangSelector} from "./MobileLangSelector";
import {useTranslation} from "react-i18next";

interface IHeaderWrapperProps {
	is_open: boolean;
	is_home: boolean;
}

const Nav = styled.div`
	flex: 1;
	display: flex;
	align-items: center;

	&.right-align {
		justify-content: flex-end;
	}

	@media screen and (max-width: 960px) {
		flex-flow: column;
		justify-content: center;
		height: 100%;
		overflow: auto;
		text-align: center;
		margin-left: 0;
	}
`;

const SponsorBlock = styled.div`
	font-size: 10px;
	text-align: center;
	color: #fff;

	p {
		margin-bottom: 10px;
	}

	img {
		display: block;
		margin: 0 auto;
	}

	@media screen and (max-width: 960px) {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const homeMenuColor = ({is_home}: IHeaderWrapperProps) => {
	return is_home
		? css`
				background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
				a,
				button {
					color: #fff;
				}
		  `
		: css`
				background: rgba(0, 0, 0, 0);
		  `;
};

const HeaderWrapper = styled.header<IHeaderWrapperProps>`
	color: #fff;
	padding: 25px 0;
	box-sizing: border-box;
	top: 0;
	left: 0;
	z-index: 15;
	width: 100%;

	img {
		display: block;
	}

	${Container} {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 15;
		> div,
		> nav {
			flex: 1;
		}
	}

	@media screen and (max-width: 960px) {
		position: ${({is_open}) => (is_open ? "fixed" : "relative")};

		${Nav},
		${SponsorBlock} {
			display: none;
		}
		img {
			width: 154px;
		}
	}

	${homeMenuColor}
`;

const NavItem = styled(NavLink)<{ishome?: boolean}>`
	margin: 0 15px;
	padding: 2px 0;
	display: flex;
	//text-transform: uppercase;
	color: #000000;
	font-family: var(--fontFamilyBase);
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 21px;
	transition: all 250ms ease-in-out;
	border-top: 1px solid rgba(0, 0, 0, 0);
	border-bottom: 1px solid rgba(0, 0, 0, 0);

	&:hover,
	&.active {
		border-bottom: 1px solid #000;
	}
	g,
	path {
		fill: #000;
	}

	&.home {
		&:hover,
		&.active {
			border-bottom: 1px solid #ffffff;
		}
		g,
		path {
			fill: #ffffff;
		}
		&.external {
			border: none;
		}
	}

	&.external {
		display: flex;
		align-items: center;
		border-bottom: none;
		border-top: none;

		&:hover,
		&.active {
			border-bottom: none;
		}
		svg {
			height: 39px;
			width: auto;
		}
	}

	&.center {
		justify-content: center;
	}

	@media screen and (max-width: 960px) {
		margin: 0;
		color: #fff;
		&.active {
			border-radius: 0;
		}
		g {
			fill: #fff;
		}
	}
`;

const HamburgerMenu = styled.button`
	margin-right: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	border: 0;
	cursor: pointer;
	background: none;
	position: absolute;
	top: -10px;
	left: 20px;
	img {
		width: 16px;
	}
`;

const MobileMenu = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	padding: 80px 0 20px;
	box-sizing: border-box;
	z-index: 14;
	color: #fff;
	display: none;

	@media screen and (max-width: 960px) {
		display: block;
	}
`;

const LogoutBtn = styled(NavLink)`
	padding: 0;
	cursor: pointer;
	font-family: var(--fontFamilyBase);
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto;
	font-weight: 600;
	width: 100%;
	max-width: 240px;
	height: 40px;
	min-height: 40px;
	background: #fff;
	color: #cf003e;
	border: 1px solid #cf003e;
`;

const prizesKey = "navigation.option.prizes";
const helpKey = "navigation.option.help";

const HomeLink = ({is_home, home_url}: {is_home?: boolean; home_url: string}) => {
	const {t} = useTranslation();
	const homeClassName = is_home ? "home" : "";
	const isActive = (match: unknown, location: RouteComponentProps["location"]) => {
		return isHome(location.pathname);
	};
	return (
		<NavItem className={homeClassName} isActive={isActive} to={home_url}>
			{t("navigation.option.pole_position", "Pole Position")}
		</NavItem>
	);
};

const LeftNavigation = ({is_home, home_url}: {is_home: boolean; home_url: string}) => {
	const {t} = useTranslation();
	const homeClassName = is_home ? "home" : "";
	const is_logged_in = useSelector(isLoggedIn);

	if (is_logged_in) {
		return (
			<Nav>
				<HomeLink is_home={is_home} home_url={home_url} />
				<NavItem to="/rankings">{t("navigation.option.rankings", "Rankings")}</NavItem>
				<NavItem to="/stats">{t("navigation.option.stats", "Stats")}</NavItem>
				<NavItem to="/prizes">{t(prizesKey, "Prizes")}</NavItem>
				<NavItem className={homeClassName} to="/help">
					{t(helpKey, "Help")}
				</NavItem>
			</Nav>
		);
	}

	return (
		<Nav>
			<HomeLink is_home={is_home} home_url={home_url} />
			<NavItem to="/prizes">{t(prizesKey, "Prizes")}</NavItem>
			<NavItem className={homeClassName} to="/help">
				{t(helpKey, "Help")}
			</NavItem>
		</Nav>
	);
};

const RightNavigation = ({is_home}: {is_home: boolean}) => {
	const {t} = useTranslation();
	const login_link = getLoginLink();
	const is_logged_in = useSelector(isLoggedIn);
	const homeClassName = is_home ? "home" : "";
	return (
		<Nav className={"right-align"}>
			{is_logged_in ? (
				<Fragment>
					<NavItem as="a" href={ACCOUNT_LINK} target="_blank">
						{t("navigation.option.account", "Account")}
					</NavItem>
					<NavItem to="/logout">{t("navigation.option.logout", "Logout")}</NavItem>
				</Fragment>
			) : (
				<Exist when={SecretGateController.IS_SECRET_PASSED}>
					<NavItem className={homeClassName} as={"a"} href={login_link}>
						{t("navigation.option.login", "Login")}
					</NavItem>
				</Exist>
			)}
			<LangSelector />
			<NavItem as={"a"} href={FANTASY_LINK}>
				{t("navigation.option.predictor", "MotoGP™ Fantasy")}
			</NavItem>
			<NavItem
				className={`${homeClassName} external`}
				as={"a"}
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.motogp.com/">
				<MotoGPLogo />
			</NavItem>
		</Nav>
	);
};

const Navigation = ({home_url}: {home_url: string}) => {
	const login_link = getLoginLink();
	const is_logged_in = useSelector(isLoggedIn);
	const {t} = useTranslation();

	return (
		<Nav>
			{is_logged_in ? (
				<Fragment>
					<HomeLink home_url={home_url} />
					<NavItem to="/rankings">{t("navigation.option.rankings", "Rankings")}</NavItem>
					<NavItem to="/stats">{t("navigation.option.stats", "Stats")}</NavItem>
				</Fragment>
			) : null}

			<NavItem to="/prizes">{t(prizesKey, "Prizes")}</NavItem>
			<NavItem to="/help">{t(helpKey, "Help")}</NavItem>
			{is_logged_in ? (
				<NavItem as="a" href={ACCOUNT_LINK} target="_blank">
					{t("navigation.option.account", "Account")}
				</NavItem>
			) : (
				<Exist when={SecretGateController.IS_SECRET_PASSED}>
					<NavItem as={"a"} href={login_link}>
						{t("navigation.option.login", "Login")}
					</NavItem>
				</Exist>
			)}
			<NavItem as={"a"} href={FANTASY_LINK}>
				{t("navigation.option.predictor", "MotoGP™ Fantasy")}
			</NavItem>
			<MobileLangSelector />
			<NavItem
				className="external center"
				as={"a"}
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.motogp.com/">
				<MotoGPLogo />
			</NavItem>
			{is_logged_in ? (
				<LogoutBtn to="/logout">{t("navigation.option.logout", "Logout")}</LogoutBtn>
			) : null}
		</Nav>
	);
};

const getMobileMenuIcon = ({is_home, is_menu_open}: {is_home: boolean; is_menu_open: boolean}) => {
	return is_home || is_menu_open ? hamburger : hamburger_dark;
};

const isHome = (pathname: string) => {
	const is_empty = pathname === "/";
	return is_empty || pathname.includes("/pole-position");
};

export const Header = () => {
	const dispatch = useDispatch();
	const is_logged_in = useSelector(isLoggedIn);
	const {pathname} = useLocation();
	const prevPathname = usePrevious(pathname);
	const is_mobile = useMediaQuery("(max-width: 960px)");
	const [is_menu_open, setMenuOpenState] = useState(false);
	const onToggleMenu = useCallback(() => setMenuOpenState(!is_menu_open), [is_menu_open]);

	const is_home = isHome(pathname);
	const logo = is_home || is_menu_open ? logo_light : logo_dark;
	const mobile_menu_icon = getMobileMenuIcon({is_home, is_menu_open});
	const home_url = is_logged_in ? "/pole-position" : "/";

	useEffect(() => {
		const classList = document.body.classList;
		is_menu_open ? classList.add("is-menu-open") : classList.remove("is-menu-open");
	}, [is_menu_open]);

	useEffect(() => setMenuOpenState(false), [is_mobile]);

	useEffect(() => {
		if (prevPathname && prevPathname !== pathname) {
			setMenuOpenState(false);
		}
	}, [pathname, prevPathname, dispatch]);

	return (
		<React.Fragment>
			<HeaderWrapper is_open={is_menu_open} is_home={is_home}>
				<Container>
					{is_mobile ? (
						<HamburgerMenu onClick={onToggleMenu}>
							<img src={mobile_menu_icon} alt="Menu" />
						</HamburgerMenu>
					) : (
						<LeftNavigation is_home={is_home} home_url={home_url} />
					)}

					<NavLink to="/">
						<img src={logo} alt="MotoGP™ Tissot Predictor" />
					</NavLink>
					<RightNavigation is_home={is_home} />
				</Container>
			</HeaderWrapper>
			{is_menu_open ? (
				<MobileMenu>
					<Navigation home_url={home_url} />
				</MobileMenu>
			) : null}
		</React.Fragment>
	);
};
