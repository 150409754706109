import React from "react";
import facebook from "assets/img/share_icons/facebook.svg";
import twitter from "assets/img/share_icons/twitter.svg";
import whatsapp from "assets/img/share_icons/whatsapp.svg";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getPrediction, isShowResults} from "modules/selectors";
import {
	PointsType,
	PREDICTION_RESULTS,
	share,
	SHARE_MESSAGE_GLOBAL,
	ShareNet,
	shareWhatsApp,
	useSelectedEvent,
} from "modules/utils";
import {WhatsappShareButton} from "react-share";
import {partial} from "lodash";
import {useTranslation} from "react-i18next";

const ShareWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;

	> div {
		color: #ffffff;
		font-family: "Heebo", sans-serif;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 24px;
		margin-right: 10px;
	}
`;

const ShareButton = styled.button`
	outline: none;
	background-color: #fff;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	border: 1px solid #9015e0;
	cursor: pointer;
	padding: 0;
	margin-right: 10px;

	> button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
`;

const getCompleteMessage = (points: PointsType | null) => {
	if (points === null) {
		return PREDICTION_RESULTS[PointsType.BeyondTwoSeconds].share_message;
	}

	return (
		PREDICTION_RESULTS[points].share_message ||
		PREDICTION_RESULTS[PointsType.BeyondTwoSeconds].share_message
	);
};

export const Share: React.FC = () => {
	const {t} = useTranslation();
	const prediction = useSelector(getPrediction);
	const event = useSelectedEvent();
	const is_complete = useSelector(isShowResults);
	if (!prediction || !event) {
		return null;
	}
	const message = is_complete ? getCompleteMessage(prediction?.points) : SHARE_MESSAGE_GLOBAL;
	const share_data = {
		sc: ShareNet.WhatsApp,
		message: message,
		prediction_id: is_complete ? prediction.id : null,
	};

	const handleShare = (type_share: ShareNet) => {
		share({
			...share_data,
			sc: type_share,
		});
	};

	const whatsApp = shareWhatsApp(share_data);

	return (
		<ShareWrapper>
			<div>{t("team.share.copy", "Share MotoGP™ Predictor")}</div>
			<ShareButton onClick={partial(handleShare, ShareNet.Facebook)}>
				<img src={facebook} alt="facebook" />
			</ShareButton>
			<ShareButton onClick={partial(handleShare, ShareNet.Twitter)}>
				<img src={twitter} alt="twitter" />
			</ShareButton>
			<ShareButton as={"div"}>
				<WhatsappShareButton {...whatsApp}>
					<img src={whatsapp} alt="whatsapp" />
				</WhatsappShareButton>
			</ShareButton>
		</ShareWrapper>
	);
};
