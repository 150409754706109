import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {
	getActualEvent,
	getEventsFromFirstToActual,
	getGameBar,
	isLoggedIn,
} from "modules/selectors";
import {ArrowSlim} from "components/Icons";
import {useHistory} from "react-router-dom";
import {FLAGS_URL, useMediaQuery, usePrevious, useSelectedEvent} from "modules/utils";
import moment from "moment";
import {isEqual, get} from "lodash";
import {IEvent} from "modules/types";
import {useTranslation} from "react-i18next";

const Nav = styled.div`
	width: 100%;
	background: #222222;
	height: 76px;
	color: #fff;
	margin-top: 50px;
	position: relative;
	z-index: 10;

	@media screen and (max-width: 960px) {
		margin-top: 20px;
		height: 113px;
	}
`;

const Events = styled.div`
	max-width: 1200px;
	display: flex;
	margin: 0 auto;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	.hidden {
		display: none;
	}
`;

const NavItem = styled.button`
	background: none;
	outline: none;
	height: 100%;
	width: 50px;
	border: none;
	cursor: pointer;
	&:disabled {
		cursor: default;
	}
`;
const SliderContainer = styled.ul<{width?: string}>`
	display: flex;
	flex-wrap: nowrap;
	height: 76px;
	width: ${({width}) => width || "100%"};
	@media screen and (max-width: 960px) {
		height: 113px;
	}
`;

const ListItem = styled.li`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;

const FlagSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Heebo", sans-serif;
	font-weight: 700;
	font-size: 16px;
	height: 35px;
	padding: 0 20px;
	img {
		width: 45px;
		margin-right: 10px;
	}
	border-right: 1px solid #fff;
	@media screen and (max-width: 960px) {
		border-right: none;
		font-size: 14px;
		border-bottom: 1px solid #313131;
		height: 52px;
		padding: 10px;
		width: 100%;
		margin: 0 10px;
		box-sizing: border-box;
		img {
			width: 39px;
		}
	}
`;

const SliderWrapper = styled.div`
	width: 100%;
	overflow: hidden;
`;

const StatsSection = styled.div`
	font-family: "Heebo", sans-serif;
	text-align: center;
	letter-spacing: 0;
	border-right: 1px solid #fff;
	height: 35px;
	padding: 0 20px;
	p:first-child {
		font-size: 12px;
		font-weight: 700;
		line-height: 18px;
		margin-bottom: 2px;
		text-transform: uppercase;
	}
	p:last-child {
		font-size: 16px;
		line-height: 18px;
	}
	&:last-child {
		border-right: none;
	}

	@media screen and (max-width: 960px) {
		margin: 10px 0;
		p:first-child {
			font-size: 10px;
			margin-bottom: 0;
		}
		p:last-child {
			font-size: 14px;
		}
	}

	@media screen and (max-width: 360px) {
		padding: 0 12px;
		p:first-child {
			line-height: 12px;
			margin-bottom: 5px;
		}
	}
`;

const MobileStats = styled.div`
	display: flex;
`;

const ACTIVE_COLOR = "#ffffff";
const DISABLED_COLOR = "#464646";
const dateKey = "game.bar.q2_date";
const dateKeyVal = "Q{{X}} DATE";

const Item: React.FC<{short_name: string; event_id: number}> = ({short_name, event_id}) => {
	const {t} = useTranslation();
	const game_bar = useSelector(getGameBar);
	const link = `${FLAGS_URL}${short_name}.png`;
	const is_mobile = useMediaQuery("(max-width: 960px)");

	if (is_mobile) {
		return (
			<ListItem>
				<FlagSection>
					<img src={link} alt={game_bar.event_name} />
					{game_bar.event_name}
				</FlagSection>
				<MobileStats>
					<StatsSection>
						<p>{t(dateKey, dateKeyVal, {X: event_id})}</p>
						<p>{moment(game_bar.start_race).format("DD/MM/YY")}</p>
					</StatsSection>
					<StatsSection>
						<p>{t("game.bar.last_points", "LAST GP PTS")}</p>
						<p>{game_bar.prediction_points || 0}</p>
					</StatsSection>
					<StatsSection>
						<p>{t("game.bar.total", "TOTAL PTS")}</p>
						<p>{game_bar.total_points || 0}</p>
					</StatsSection>
				</MobileStats>
			</ListItem>
		);
	}

	return (
		<ListItem>
			<FlagSection>
				<img src={link} alt={game_bar.event_name} />
				{game_bar.event_name}
			</FlagSection>
			<StatsSection>
				<p>{t(dateKey, dateKeyVal, {X: event_id})}</p>
				<p>{moment(game_bar.start_race).format("DD/MM/YY")}</p>
			</StatsSection>
			<StatsSection>
				<p>{t("game.bar.last_points", "LAST GP PTS")}</p>
				<p>{game_bar.prediction_points || "-"}</p>
			</StatsSection>
			<StatsSection>
				<p>{t("game.bar.total", "TOTAL PTS")}</p>
				<p>{game_bar.total_points}</p>
			</StatsSection>
		</ListItem>
	);
};

const getColor = (is_disabled: boolean) => (is_disabled ? DISABLED_COLOR : ACTIVE_COLOR);

interface IProps {
	selected_event: IEvent;
}

export const LoggedInEventNavigation: React.FC<IProps> = ({selected_event}) => {
	const history = useHistory();
	const last_event = useSelector(getActualEvent);
	const events = useSelector(getEventsFromFirstToActual);
	const is_prev_disabled = isEqual(selected_event?.position, events[0]?.position);
	const is_next_disabled = isEqual(selected_event?.position, last_event?.position);
	const [slider_width, setSliderWidth] = useState(`${events.length * 100}%`);
	const prev_color = getColor(is_prev_disabled);
	const next_color = getColor(is_next_disabled);
	const prev_event = usePrevious(selected_event);

	const slider_wrap_ref = useRef<HTMLDivElement>(null);
	const slider_ref = useRef<HTMLUListElement>(null);

	const moveSlider = useCallback(
		(mod: number, add = 1) => {
			if (!slider_ref.current) {
				return;
			}
			const width = slider_wrap_ref?.current?.clientWidth || 0;
			const new_translate = mod * width * add;
			slider_ref.current.style.transform = `translate(${new_translate}px)`;
		},
		[slider_ref]
	);

	const changeSelectedEvent = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (!selected_event) {
			return;
		}

		const {side} = e.currentTarget.dataset;
		const mod = side === "prev" ? 1 : -1;

		history.push(`/pole-position/${selected_event.position - mod}`);
	};

	useEffect(() => {
		if (slider_width === "0%" && selected_event) {
			setSliderWidth(`${events.length * 100}%`);
			moveSlider(-1, selected_event.position - 1);
		}
	}, [events, slider_width, moveSlider, selected_event]);

	useEffect(() => {
		const is_no_prev_event = !prev_event && selected_event;
		const is_diff_event =
			prev_event && selected_event && selected_event.position !== prev_event.position;

		if (is_no_prev_event || is_diff_event) {
			moveSlider(-1, selected_event.position - 1);
		}
	}, [prev_event, selected_event, moveSlider]);

	return (
		<Nav>
			<Events>
				<NavItem
					aria-label={"Previous event"}
					onClick={changeSelectedEvent}
					data-side={"prev"}
					disabled={is_prev_disabled}>
					<ArrowSlim to={"left"} color={prev_color} />
				</NavItem>
				<SliderWrapper ref={slider_wrap_ref}>
					<SliderContainer
						ref={slider_ref}
						width={slider_width}
						className={`active-${selected_event.position}`}>
						{events.map((event, index) => (
							<Item
								event_id={event.position}
								short_name={event.short_name}
								key={index}
							/>
						))}
					</SliderContainer>
				</SliderWrapper>
				<NavItem
					aria-label={"Next event"}
					onClick={changeSelectedEvent}
					data-side={"next"}
					disabled={is_next_disabled}>
					<ArrowSlim to={"right"} color={next_color} />
				</NavItem>
			</Events>
		</Nav>
	);
};

const LoggedOutEventNavigation: React.FC<IProps> = ({selected_event}) => {
	const {t} = useTranslation();
	const {short_name, name, races, lock_date, id} = selected_event;
	const link = `${FLAGS_URL}${short_name}.png`;
	const start_race = get(races, "[1].start", lock_date);
	return (
		<Nav>
			<Events>
				<SliderWrapper>
					<SliderContainer>
						<ListItem>
							<FlagSection>
								<img src={link} alt={name} />
								{name}
							</FlagSection>
							<StatsSection>
								<p>{t(dateKey, dateKeyVal, {X: id})}</p>
								<p>{moment(start_race).format("DD/MM/YY")}</p>
							</StatsSection>
						</ListItem>
					</SliderContainer>
				</SliderWrapper>
			</Events>
		</Nav>
	);
};

export const EventsNavigation: React.FC = () => {
	const is_logged_in = useSelector(isLoggedIn);
	const selected_event = useSelectedEvent();

	if (!selected_event) {
		return null;
	}

	return is_logged_in ? (
		<LoggedInEventNavigation selected_event={selected_event} />
	) : (
		<LoggedOutEventNavigation selected_event={selected_event} />
	);
};
